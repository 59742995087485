<template>
    <div class="section-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'" />
        <div class="form-wrapper training-wrapper">
            <b-card :title="$t('teaGardenDashboard.adv_visit_title')">
                <b-row>
                    <b-col xs="12" sm="12" md="4">
                        <b-card-text>
                            <ValidationProvider name="type" vid="type">
                                <b-form-group slot-scope="{ valid, errors }" label-for="type">
                                    <b-form-radio-group :state="errors[0] ? false : (valid ? true : null)" v-model="search.type" :options="manualList"></b-form-radio-group>
                                </b-form-group>
                            </ValidationProvider>
                        </b-card-text>
                    </b-col>
                    <b-col xs="12" sm="12" md="3">
                        <b-card-text>
                            <ValidationProvider name="fiscal_year_id" vid="fiscal_year_id">
                                <b-form-group
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="clone_info_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-card-text>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" v-if="search.type === 2">
                        <b-card-text>
                            <ValidationProvider name="schedule_id" vid="schedule_id">
                                <b-form-group
                                label-for="schedule_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('teaGardenBtriService.schedule')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="search.schedule_id"
                                :options="advVistScheduleList"
                                id="clone_info_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-card-text>
                    </b-col>
                </b-row>
            </b-card>
            <div class="info-card-wrapper">
                <b-overlay :show="loading">
                <b-card :title="getTypeName(search.type)">
                    <b-card-text>
                        <b-row>
                            <b-col class="bdr" sm="6" v-if="search.type === 1">
                                <router-link :to="{name: 'tea_garden_service.btri.online_visit_schedule_list', query:{fiscal_year_id: search.fiscal_year_id, status: 0, limit: 100} }">
                                <div class="info-card application">
                                    <div class="content">
                                        <p>{{ $t('sitePreference.total') }} {{ $t('globalTrans.application') }}</p>
                                        <h3>{{ $n(totalInfo.totalApplication) }}</h3>
                                    </div>
                                    <img src="../../../../../assets/images/application.png" class="img-fluid" alt="">
                                </div>
                                </router-link>
                            </b-col>
                            <b-col class="bdr mt-3 mt-sm-0" sm="6" v-if="search.type === 1">
                                 <router-link :to="{name: 'tea_garden_service.btri.online_visit_schedule_list', query:{fiscal_year_id: search.fiscal_year_id, status: 4, limit: 100} }">
                                    <div class="info-card visit">
                                        <div class="content">
                                            <p>{{ $t('teaGardenDashboard.garden_visit') }}</p>
                                            <h3>{{ $n(totalInfo.totalVisit) }}</h3>
                                        </div>
                                        <img src="../../../../../assets/images/garden-visit.png" class="img-fluid" alt="">
                                    </div>
                                 </router-link>
                            </b-col>
                            <b-col class="bdr mt-3 mt-sm-0" sm="6" v-else>
                                <router-link :to="{ name: 'tea_garden_service.btri.visit_application_list', params: {id: search.schedule_id}, query:{fiscal_year_id: search.fiscal_year_id,status: 2, limit: 100} }">
                                <div class="info-card visit">
                                    <div class="content">
                                        <p>{{ $t('teaGardenDashboard.garden_visit') }}</p>
                                        <h3>{{ $n(totalInfo.totalVisit) }}</h3>
                                    </div>
                                    <img src="../../../../../assets/images/garden-visit.png" class="img-fluid" alt="">
                                </div>
                                </router-link>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    export default {
        name: 'AdvisoryVisitBtri',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                totalInfo: {
                    totalApplication: 0,
                    totalVisit: 0
                },
                search: {
                    fiscal_year_id: this.$store.state.currentFiscalYearId,
                    type: 1,
                    schedule_id: 0
                }
            }
        },
        created () {
            this.search.schedule_id = this.advVistScheduleList[0].value
            this.loadData()
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            fiscalYearList () {
                return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
            },
            advVistScheduleList () {
                return this.$store.state.TeaGardenService.commonObj.btriAdvVisitScheduleList.filter(item => item.status > 1)
            },
            manualList () {
                const typeList = [
                    { value: 1, text: this.currentLocale === 'bn' ? 'বাগানের অনুরোধ' : 'Garden Requested' },
                    { value: 2, text: this.currentLocale === 'bn' ? 'বিটিআরআই পরিদর্শন সূচী' : 'BTRI Schedule' }
                ]
                return typeList
            }
        },
        methods: {
            async loadData () {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/advisory-visit-garden', this.search)
                if (result.success) {
                    this.totalInfo = result.data.totalInfo
                } else {
                 this.totalInfo = {
                        totalApplication: 0,
                        totalVisit: 0
                    }
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            getTypeName (id) {
                const obj = this.manualList.find(item => item.value === parseInt(id))
                return obj?.text
            }
        },
        watch: {
            'search.fiscal_year_id': function (newVal, oldVal) {
                this.loadData()
            },
            'search.type': function (newVal, oldVal) {
               this.loadData()
            },
            'search.schedule_id': function (newVal, oldVal) {
                this.loadData()
            }
        }
    }
</script>
<style>
.training-wrapper .info-card-wrapper .info-card {
    border-radius: 1rem;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.training-wrapper .info-card-wrapper .info-card .content p {
    font-size: 25px;
}

.training-wrapper .info-card-wrapper .info-card.application {
    background: linear-gradient(90deg, rgba(64, 123, 255, 1) 0%, rgba(159, 189, 255, 1) 100%);
}

.training-wrapper .info-card-wrapper .info-card.visit {
    background: linear-gradient(90deg, rgb(63, 179, 98) 0%, rgb(134, 227, 162) 100%);
}

.training-wrapper .info-card-wrapper .info-card img {
    height: 120px;
}
</style>
