<template>
  <div class="section-wrapper">
    <breadcumb />
    <b-overlay :show="loading">
      <b-row v-if="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()">
        <b-col>
            <b-card class="mt-1 text-white" style="background: #12B338">
                <div v-if="$i18n.locale === 'en'" style="font-size: 25px" class="text-center mt-2 mb-2">
                      <div>Welcome to <strong>{{ getGardenFactName() + '\'s' }}</strong> Dashboard</div>
                </div>
                <div v-else style="font-size: 25px" class="text-center mt-2 mb-2">
                      <div><b>{{ getGardenFactName() }}</b> এর ড্যাশবোর্ড এ আপনাকে স্বাগতম</div>
                </div>
            </b-card>
        </b-col>
      </b-row>
      <div class="form-wrapper" style="position: sticky;top: 53px;z-index: 100;"> <!-- bms-db-wrapper search_bar_fixed -->
        <b-card>
          <b-card-text>
            <b-row>
              <!-- service authority -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Service Authority" vid="service_type">
                  <b-form-group
                    label-for="service_type"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('teaGardenDashboard.service_authority')}}
                  </template>
                  <b-form-select
                    @change="getServiceList"
                    plain
                    v-model="search.service_type"
                    :options="serviceAuthorityList"
                    id="service_type"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- service -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Service Name" vid="service_id">
                  <b-form-group
                    label-for="service_id"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('teaGardenConfig.service_name')}}
                  </template>
                  <v-select
                    id="service_id"
                    v-model="search.service_id"
                    :reduce="op => op.value"
                    :options="serviceList"
                    label="text"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                    >
                    </v-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </div>
      <!-- tea gardens dashboard -->
        <b-row>
          <b-col sm="6">
            <div class="mb-1" id="dashboard_start_point">
              <div class="faq-body mt-2">
                <div class="form-wrapper fertilizer-distribution">
                  <b-card class="total-gardens">
                      <b-row no-gutters>
                        <b-col sm="6">
                          <router-link :to="{ name: 'tea_garden_service.configuration.tea_garden_profile', query: { status: 1, limit: 100 } }">
                          <div class="total-garden-card">
                            <i class="ri-seedling-fill"></i>
                            <div class="content">
                              <p>{{ $t('teaGardenDashboard.total_gandens') }}</p>
                              <h3>{{ $n(masterTeaGardenGenInfoList.length) }}</h3>
                            </div>
                          </div>
                          </router-link>
                        </b-col>
                        <b-col sm="6">
                          <img src="../../../../assets/images/tea_gardens.jpg" class="img-fluid" alt="Tea Garden Image">
                        </b-col>
                      </b-row>
                  </b-card>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="6">
            <div class="mb-1" id="dashboard_start_point">
              <div class="faq-body mt-2">
                <div class="form-wrapper fertilizer-distribution">
                  <b-card class="total-gardens">
                    <b-row no-gutters>
                      <b-col sm="6">
                        <router-link :to="{ name: 'tea_garden_service.configuration.bought_leaf_factory', query: { factory_type_id: 2, limit: 100 } }">
                        <div class="total-garden-card">
                          <i class="ri-building-2-fill"></i>
                          <div class="content">
                            <p>{{ $t('teaGardenDashboard.total_factory') }}</p>
                            <h3>{{ $n(masterBoughtLeafFactoryList.length) }}</h3>
                          </div>
                        </div>
                        </router-link>
                      </b-col>
                      <b-col sm="6">
                        <img src="../../../../assets/images/Boughtleaf.jpg" class="img-fluid" alt="Tea Garden Image">
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div>
          <!-- Fertilizer Distribution -->
          <div class="mb-1" id="FertilizerDistribution" ref="FertilizerDistribution" v-if="serviceIds.includes(19)">
            <FertilizerDistribution/>
          </div>
          <!-- Tree Disposal NOC -->
          <div class="mb-1" id="TreeDisposalDashboard" ref="TreeDisposalDashboard" v-if="serviceIds.includes(2)">
            <TreeDisposalDashboard/>
          </div>
          <!-- Small Tea Garden Resistration & Reniew -->
          <div class="mb-1" id="BtbGardenServiceDashboard" ref="BtbGardenServiceDashboard" v-if="serviceIds.includes(1)">
            <BtbGardenServiceDashboard/>
          </div>
          <!-- Small Tea Farmer -->
          <div class="mb-1" id="SmallTeaFarmerDashboard" ref="SmallTeaFarmerDashboard" v-if="serviceIds.includes(3)">
            <SmallTeaFarmerDashboard/>
          </div>
          <!-- Tea Factory Dashboard-->
          <div class="mb-1" id="TeaFactoryDashboard" ref="TeaFactoryDashboard" v-if="serviceIds.includes(4)">
            <TeaFactoryDashboard/>
          </div>
          <!-- BTRI TEST Dashboard -->
          <div class="mb-1" id="BtriTestDashboard" ref="BtriTestDashboard" v-if="serviceIds.includes(5) || serviceIds.includes(6) || serviceIds.includes(7) || serviceIds.includes(8) || serviceIds.includes(9) || serviceIds.includes(10) || serviceIds.includes(11) || serviceIds.includes(12) || serviceIds.includes(13) || serviceIds.includes(14) || serviceIds.includes(15) || serviceIds.includes(16)">
            <BtriTestDashboard/>
          </div>
          <!-- Advisory Visit -->
          <div class="mb-1" id="AdvisoryVisitBtri" ref="AdvisoryVisitBtri" v-if="serviceIds.includes(22)">
            <AdvisoryVisitBtri/>
          </div>
          <div class="mb-1" id="RegularGardenInspection" ref="RegularGardenInspection" v-if="serviceIds.includes(28)">
            <RegularGardenInspection/>
          </div>
          <div class="mb-1" id="TrainingWorkshop" ref="TrainingWorkshop" v-if="serviceIds.includes(23)">
            <TrainingWorkshop/>
          </div>
          <div class="mb-1" id="TeaPlantSupply" ref="TeaPlantSupply" v-if="serviceIds.includes(21)">
            <TeaPlantSupply/>
          </div>
          <div class="mb-1" id="CloneBiCloneDashboard" ref="CloneBiCloneDashboard" v-if="serviceIds.includes(20)">
            <CloneBiCloneDashboard/>
          </div>
          <!-- Professional Course Management -->
          <div class="mb-1" id="ProfessionalCourseManagement" ref="ProfessionalCourseManagement" v-if="serviceIds.includes(17)">
            <ProfessionalCourseManagement/>
          </div>
          <div class="mb-1" id="EducationTrustScholarship" ref="EducationTrustScholarship" v-if="serviceIds.includes(29)">
            <EducationTrustScholarship/>
          </div>
          <div class="mb-1" id="ReturnDashboard" ref="ReturnDashboard" v-if="serviceIds.includes(24) || serviceIds.includes(25) || serviceIds.includes(26)">
            <ReturnDashboard/>
          </div>
          <div class="mb-1" id="OnlineTeaLicenseDashboard" ref="OnlineTeaLicenseDashboard" v-if="serviceIds.includes(30) || serviceIds.includes(31) || serviceIds.includes(32) || serviceIds.includes(33) || serviceIds.includes(34)">
            <OnlineTeaLicenseDashboard/>
          </div>
          <div class="mb-1" id="TeaProductionDashboard" ref="TeaProductionDashboard" v-if="serviceIds.includes(26)">
            <TeaProductionDashboard/>
          </div>
        </div>
    </b-overlay>
  </div>
</template>
<script>
import TreeDisposalDashboard from './dashboard/TreeDisposalDashboard.vue'
import SmallTeaFarmerDashboard from './dashboard/SmallTeaFarmerDashboard.vue'
import BtbGardenServiceDashboard from './dashboard/BtbGardenServiceDashboard.vue'
import TeaFactoryDashboard from './dashboard/TeaFactoryDashboard.vue'
import BtriTestDashboard from './dashboard/BtriTestDashboard.vue'
import ProfessionalCourseManagement from './dashboard/pdu/ProfessionalCourseManagement.vue'
import FertilizerDistribution from './dashboard/FertilizerDistribution.vue'
import AdvisoryVisitBtri from './dashboard/AdvisoryVisitBtri.vue'
import ReturnDashboard from './dashboard/BtbSix.vue'
import TrainingWorkshop from './dashboard/TrainingWorkshop.vue'
import TeaPlantSupply from './dashboard/TeaPlantSupply.vue'
import CloneBiCloneDashboard from './dashboard/CloneBiCloneDashboard.vue'
import EducationTrustScholarship from './dashboard/pdu/EducationTrustScholarship.vue'
import OnlineTeaLicenseDashboard from './dashboard/OnlineTeaLicenseDashboard.vue'
import TeaProductionDashboard from './dashboard/TeaProductionDashboard.vue'
import RegularGardenInspection from './dashboard/pdu/RegularGardenInspection.vue'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  components: {
    TreeDisposalDashboard,
    SmallTeaFarmerDashboard,
    BtbGardenServiceDashboard,
    TeaFactoryDashboard,
    ProfessionalCourseManagement,
    BtriTestDashboard,
    FertilizerDistribution,
    AdvisoryVisitBtri,
    ReturnDashboard,
    TrainingWorkshop,
    TeaPlantSupply,
    CloneBiCloneDashboard,
    EducationTrustScholarship,
    OnlineTeaLicenseDashboard,
    TeaProductionDashboard,
    RegularGardenInspection
   },
  data () {
    return {
      search: {
        service_type: 0,
        service_id: ''
      },
      dashboardData: {
      },
      serviceList: [],
      serviceIds: [],
      ref_collenction_service_id: '',
      loading: false
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    masterTeaGardenGenInfoList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    masterBoughtLeafFactoryList () {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1 && el.factory_type_id === 2)
    },
    serviceAuthorityList () {
      return this.$store.state.TeaGardenService.commonObj.serviceAuthority
    }
  },
  created () {
    this.getServiceList()
  },
  methods: {
    getGardenFactName () {
      const Id = this.isGardenAdminCheckGardenId() ? this.isGardenAdminCheckGardenId() : this.isFactoryAdminCheckFactoryId()
      const List = this.isGardenAdminCheckGardenId() ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
      const Obj = List.find(el => el.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
          return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
      }
      return ''
    },
    getServiceList () {
      this.serviceList = []
      this.serviceIds = []
      this.search.service_id = ''
      if (this.search.service_type) {
        this.serviceList = this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(this.search.service_type))
      } else {
        this.serviceList = this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => item.status === 1)
      }
      this.serviceList.forEach(element => {
        this.serviceIds.push(element.value)
      })
    },
    scrollToBottom () {
      if (this.search.service_id) {
        if (this.search.service_id === 1) {
          const yOffset = -170
          const element = document.getElementById('BtbGardenServiceDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
          // this.$refs.BtbGardenServiceDashboard.scrollIntoView({ behavior: 'smooth' })
        } else if (this.search.service_id === 2) {
          const yOffset = -170
          const element = document.getElementById('TreeDisposalDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 3) {
          const yOffset = -170
          const element = document.getElementById('SmallTeaFarmerDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 4) {
          const yOffset = -170
          const element = document.getElementById('TeaFactoryDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 17) {
          const yOffset = -170
          const element = document.getElementById('ProfessionalCourseManagement')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id > 4 && this.search.service_id < 17) {
          const yOffset = -170
          const element = document.getElementById('BtriTestDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 19) {
          const yOffset = -170
          const element = document.getElementById('FertilizerDistribution')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 22) {
          const yOffset = -170
          const element = document.getElementById('AdvisoryVisitBtri')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 28) {
          const yOffset = -170
          const element = document.getElementById('RegularGardenInspection')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 23) {
          const yOffset = -170
          const element = document.getElementById('TrainingWorkshop')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 21) {
          const yOffset = -170
          const element = document.getElementById('TeaPlantSupply')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 20) {
          const yOffset = -170
          const element = document.getElementById('CloneBiCloneDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 29) {
          const yOffset = -170
          const element = document.getElementById('EducationTrustScholarship')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if (this.search.service_id === 24 || this.search.service_id === 25 || this.search.service_id === 26) {
          const yOffset = -170
          const element = document.getElementById('ReturnDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        } else if ([30, 31, 32, 33, 34].includes(this.search.service_id)) {
          const yOffset = -170
          const element = document.getElementById('OnlineTeaLicenseDashboard')
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      }
    },
    goToTop () {
      const yOffset = -250
      const element = document.getElementById('dashboard_start_point')
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  },
  watch: {
    'search.service_id': function (newVal, Oldval) {
      if (newVal) {
        this.scrollToBottom()
      } else {
        this.goToTop()
      }
    },
    currentLocale: function () {
       this.getServiceList()
    }
  }
}
</script>
<style>
.faq-body{
  margin-top: 1rem;
}
.faq-accordion {
  background-color: #F4F4F4 !important;
}

.faq-accordion .faq-body {
  padding: 10px;
  background-color: #ffffff !important;
  border: 1px solid #ddd !important;
  border-top: none;
}

.faq-btn {
  padding: 0.48rem 0.75rem;
  color: var(--alt) !important;
  border-color: var(--gray-ddd) !important;
  background-color: var(--gray-ddd) !important;
}

.faq-btn .down-arrow {
  display: block;
}

.faq-btn.not-collapsed .up-arrow {
  display: block;
}

.faq-btn.not-collapsed .down-arrow {
  display: none;
}
.search_bar_fixed {
    position: fixed;
    top: 94px;
    width: 100%;
    max-width: 77.8%;
    z-index: 5;
}

.total-gardens .total-garden-card p {
    font-size: 17px!important;
}
</style>
