<template>
    <div class="section-wrapper btri-db-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
            <b-card class="header-img">
                <h2>{{ $t('teaGardenDashboard.tea_plant_supply') }}</h2>
            </b-card>
            <div class="info-card-wrapper">
                <b-card>
                    <b-row>
                        <b-col sm="3" md="3" lg="3">
                            <router-link class="text-white" :to="{name: 'tea_garden_service.btri.service_app_list', query:{status: 0, limit: 100} }">
                            <div class="info-card">
                                <i class="ri-file-copy-2-fill"></i>
                                <div class="content">
                                    <p>{{ $t('sitePreference.total') }} {{ $t('globalTrans.application') }}</p>
                                    <h3>{{ $n(totApplication) }}</h3>
                                </div>
                            </div>
                            </router-link>
                        </b-col>
                        <b-col sm="3" md="3" lg="3">
                            <router-link class="text-white" :to="{name: 'tea_garden_service.btri.service_app_list', query:{status: 1, limit: 100} }">
                                <div class="info-card">
                                    <i class="ri-file-copy-2-fill"></i>
                                    <div class="content">
                                        <p>{{ $t('globalTrans.pending') }} {{ $t('globalTrans.application') }}</p>
                                        <h3>{{ $n(pendingApplication) }}</h3>
                                    </div>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col sm="3" md="3" lg="3">
                            <router-link class="text-white" :to="{name: 'tea_garden_service.btri.service_app_list', query:{status: 3, limit: 100} }">
                                <div class="info-card">
                                    <i class="ri-file-copy-2-fill"></i>
                                    <div class="content">
                                        <p>{{ $t('globalTrans.approved') }} {{ $t('globalTrans.application') }}</p>
                                        <h3>{{ $n(approvedApplication) }}</h3>
                                    </div>
                                </div>
                            </router-link>
                        </b-col>
                        <!-- <b-col sm="3" md="3" lg="3">
                            <router-link class="text-white" :to="{name: 'tea_garden_service.btri.service_app_list', query:{status: 3, limit: 100} }">
                                <div class="info-card">
                                    <i class="ri-file-copy-2-fill"></i>
                                    <div class="content">
                                        <p>{{ $t('allocation_management.distributed') }} {{ $t('globalTrans.application') }}</p>
                                        <h3>{{ $n(distributedApplication) }}</h3>
                                    </div>
                                </div>
                            </router-link>
                        </b-col> -->
                        <b-col sm="3" md="3" lg="3">
                            <router-link class="text-white" :to="{name: 'tea_garden_service.btri.service_app_list', query:{status: 4, limit: 100} }">
                                <div class="info-card">
                                    <i class="ri-file-copy-2-fill"></i>
                                    <div class="content">
                                        <p>{{ $t('allocation_management.distributed') }} {{ $t('globalTrans.quantity') }}</p>
                                        <h3>{{ $n(distributedQty) }}</h3>
                                    </div>
                                </div>
                            </router-link>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </b-overlay>
        <!-- <div class="data-chart">
            <b-card>
                <img src="../../../../../assets/images/chart-dark.png" class="img-fluid" alt="">
            </b-card>
        </div> -->
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  components: {},
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      errors: [],
      totApplication: 0,
      pendingApplication: 0,
      approvedApplication: 0,
      distributedApplication: 0,
      distributedQty: 0
    }
  },
  created () {
    this.loadData()
  },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        loading () {
            return this.$store.state.commonObj.loading
        }
    },
    methods: {
        searchData () {
        this.loadData()
        },
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/tea-pant-supply', this.search)
            if (result.success) {
                if (result.data) {
                    result.data.map((item) => {
                        if (item.status === 1) {
                            this.pendingApplication = item.total
                        } else if (item.status === 3) {
                            this.approvedApplication = item.total
                        } else if (item.status === 4) {
                            this.distributedApplication = item.total
                            this.distributedQty = item.disburseQty
                        }
                    })
                    this.totApplication = this.pendingApplication + this.approvedApplication
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    }
}
</script>
<style>
.btri-db-wrapper .header-img {
    height: 200px;
}

.btri-db-wrapper .header-img .card-body {
    padding: 0;
    background-image: linear-gradient(to bottom, rgba(34, 38, 47, 0.729), rgba(34, 38, 47, 0.729)),
        url('../../../../../assets/images/tea_gardens.jpg');
    background-position: center;
    background-size: cover;

}

.btri-db-wrapper .card .card-body h2 {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-top: 40px;
}

.btri-db-wrapper .info-card-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.btri-db-wrapper .info-card-wrapper .card {
    width: 90%;
    min-height: 100px;
    margin-top: -75px;
    color: #fff;
    background: var(--alt);
}

.btri-db-wrapper .info-card-wrapper .card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btri-db-wrapper .info-card-wrapper .info-card {
    text-align: center;
}

.btri-db-wrapper .info-card-wrapper .row div {
    border-right: 1px solid #ddd;
}

.btri-db-wrapper .info-card-wrapper .row div:last-child {
    border: none;
}
</style>
