<template>
    <div class="section-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
            <div class="form-wrapper training-wrapper">
                <b-card :title="$t('teaGardenDashboard.event_type_title')">
                    <b-row>
                        <b-col>
                            <b-card-text>
                                <b-form-group :label="$t('teaGardenBtriService.event_type')">
                                    <b-form-radio-group
                                    name="radio-inline"
                                    v-model="search.event_type_id"
                                    :options="eventTypeList"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-card-text>
                        </b-col>
                    </b-row>
                </b-card>
                <div class="info-card-wrapper">
                    <b-card :title="$t('teaGardenBtriService.event_name')">
                        <b-card-text>
                            <b-row>
                                <b-col class="bdr" sm="6">
                                    <router-link :to="{name: 'tea_garden_service.btri.prepare_schedule', query:{event_type: 1, limit: 100} }">
                                    <div v-if="search.event_type_id === 1"  class="info-card training">
                                        <div class="content">
                                            <template >
                                                <p>{{ $t('teaGardenDashboard.no_of_training') }}</p>
                                                <h3>{{ $n(totalEvents) }}</h3>
                                            </template>
                                        </div>
                                        <img src="../../../../../assets/images/training.png" class="img-fluid" alt="">
                                    </div>
                                </router-link>
                                <router-link :to="{name: 'tea_garden_service.btri.prepare_schedule', query:{event_type: 2, limit: 100} }">
                                    <div v-if="search.event_type_id === 2"  class="info-card training">
                                        <div class="content">
                                            <template >
                                                <p> {{ $t('teaGardenDashboard.no_of_workshop') }} </p>
                                                <h3>{{ $n(totalEvents) }}</h3>
                                            </template>
                                        </div>
                                        <img src="../../../../../assets/images/training.png" class="img-fluid" alt="">
                                    </div>
                                </router-link>
                                <router-link :to="{name: 'tea_garden_service.btri.prepare_schedule', query:{event_type: 3, limit: 100} }">
                                    <div v-if="search.event_type_id === 3"  class="info-card training">
                                        <div class="content">
                                            <template >
                                                <p> {{ $t('teaGardenDashboard.no_of_course') }} </p>
                                                <h3>{{ $n(totalEvents) }}</h3>
                                            </template>
                                        </div>
                                        <img src="../../../../../assets/images/training.png" class="img-fluid" alt="">
                                    </div>
                                </router-link>
                                 </b-col>
                                <b-col class="bdr mt-3 mt-sm-0" sm="6">
                                    <router-link :to="{name: 'tea_garden_service.btri.prepare_schedule', query:{event_type: 3, limit: 100} }">
                                    <div class="info-card participant">
                                        <div class="content">
                                            <p> {{ $t('teaGardenDashboard.no_of_participants') }} </p>
                                            <h3>{{ $n(totalParticipants) }}</h3>
                                        </div>
                                        <img src="../../../../../assets/images/participant.png" class="img-fluid" alt="">
                                    </div>
                                </router-link>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  components: {},
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      errors: [],
      search: {
          event_type_id: 1,
          status: this.$route.query.status ? this.$route.query.status : 0,
          limit: this.$route.query.limit ? this.$route.query.limit : 10
      },
    totalEvents: 0,
    totalParticipants: 0
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    eventTypeList: function () {
        const list = [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
        ]
        return list
    },
    currentLocale () {
        return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  // create watch for event type change and call loadData function
    watch: {
        'search.event_type_id': function (val) {
            this.loadData()
        }
    },
    methods: {
        searchData () {
        this.loadData()
        },
        async loadData () {
            this.totalEvents = 0
            this.totalParticipants = 0
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/training-workshop', this.search)
            if (result.success) {
                if (result.data) {
                    this.totalEvents = result.data.totalEvents
                    this.totalParticipants = result.data.totalParticipants
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
    }
}
</script>
<style>
.training-wrapper .info-card-wrapper .info-card {
    border-radius: 1rem;
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.training-wrapper .info-card-wrapper .info-card .content p {
    font-size: 25px;
}

.training-wrapper .info-card-wrapper .info-card.training {
    background: linear-gradient(90deg, rgba(230, 131, 61, 1) 0%, rgba(242, 182, 67, 1) 100%);
}

.training-wrapper .info-card-wrapper .info-card.participant {
    background: linear-gradient(90deg, #be39d6 0%, #e873fc 100%);
}

.training-wrapper .info-card-wrapper .info-card img {
    height: 120px;
    width: auto;
}
</style>
